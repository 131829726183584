import { graphql } from "gatsby"
// import { FixedObject } from "gatsby-image"
import React from "react"
import { slugify } from "../../../utils"
import TabContent from "../../molecules/TabContent"
import DatoProductFinderShortcut from "../DatoProductFinderShortcut"
import * as styles from "./DatoProductFinderTabs.module.scss"

const DatoProductFinderTabs = (props: Queries.DatoProductFinderTabsFragment) => {
  //console.log('Rendering DatoProductFinderTabs', props)

  if (!props.productFinderShortcutLists?.length) return null

  return (
    <div id={slugify("tabs", props.originalId)}>
      <TabContent
        tabs={props.productFinderShortcutLists.map(list => {
          const shortcuts = list?.productFinderShortcuts
          if (!shortcuts) {
            return
          }
          return {
            title: list.title,
            slug: slugify(`tab ${list.title}`),
            content: (
              <div className={styles.content + " layout-container"}>
                {shortcuts?.map((productShortcut, i) => {
                  return productShortcut ? <DatoProductFinderShortcut key={i} {...productShortcut} /> : null
                })}
              </div>
            ),
          }
        })}
      />
    </div>
  )
}

export default DatoProductFinderTabs

export const query = graphql`
  fragment DatoProductFinderTabs on DatoCmsProductFinderTabSet {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    originalId
    productFinderShortcutLists {
      title
      productFinderShortcuts {
        ...DatoProductFinderShortcut
      }
    }
  }
`
